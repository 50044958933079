import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  CreateCommentFormData,
  CreateNestedCommentFormData,
  EditCommentFormData,
} from "./commentInterface";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const COMMENT_API_URL = `${backendUrl}/api/comments`;

const commentApi = createApi({
  reducerPath: "commentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: COMMENT_API_URL,
    credentials: "include", // For handling cookies/session tokens
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Comments"], // Add tags for invalidating cache
  endpoints: (builder) => ({
    // CREATE A NEW COMMENT
    createComment: builder.mutation({
      query: (CommentInfo: CreateCommentFormData) => ({
        url: "/create=new=comment",
        method: "POST",
        body: CommentInfo,
      }),
      invalidatesTags: ["Comments"], // Invalidate cache after creating a comment
    }),

    // FETCH COMMENTS FOR A POST
    getCommentsForAPost: builder.query({
      query: (postId: string) => `/${postId}/fetchcomments`,
      providesTags: ["Comments"], // Cache these comments
    }),

    // CREATE NESTED COMMENTS
    createNestedComments: builder.mutation({
      query: (CommentInfo: CreateNestedCommentFormData) => ({
        url: "/commentreply/nested==comment/",
        method: "POST",
        body: CommentInfo,
      }),
      invalidatesTags: ["Comments"],
    }),

    // REACT TO A COMMENT
    reactToComment: builder.mutation({
      query: ({ emoji, commentId }: { emoji: string; commentId: string }) => ({
        url: "/reactions/==comment=/reacttocomment/",
        method: "PATCH",
        body: { emoji, commentId },
      }),
      invalidatesTags: ["Comments"],
    }),

    // EDIT A COMMENT
    editMyComment: builder.mutation({
      query: ({
        CommentInfo,
        commentId,
      }: {
        CommentInfo: EditCommentFormData;
        commentId: string;
      }) => ({
        url: `/${commentId}/edit---comment`,
        method: "PATCH",
        body: CommentInfo,
      }),
      invalidatesTags: ["Comments"],
    }),

    // DELETE A COMMENT
    deleteMyComment: builder.mutation({
      query: (commentId: string) => ({
        url: `/${commentId}/delete---comment`,
        method: "DELETE",
      }),
      invalidatesTags: ["Comments"],
    }),

    // REPORT A COMMENT
    reportUserComment: builder.mutation({
      query: (commentId: string) => ({
        url: `/${commentId}/report---user`,
        method: "POST",
      }),
    }),

    // FETCH ALL COMMENTS (Admin Dashboard)
    fetchAllComments: builder.query({
      query: () => "/get--all--comments",
      providesTags: ["Comments"],
    }),
  }),
});

export const {
  useCreateCommentMutation,
  useGetCommentsForAPostQuery,
  useCreateNestedCommentsMutation,
  useReactToCommentMutation,
  useEditMyCommentMutation,
  useDeleteMyCommentMutation,
  useReportUserCommentMutation,
  useFetchAllCommentsQuery,
} = commentApi;

export default commentApi;
