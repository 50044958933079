import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FilterUsersPayload, UserData } from "./authInterfaces"; // Assuming you have UserData defined elsewhere

interface AuthState {
  user: UserData | null;
  isLoggedIn: boolean;
  isSuccess: boolean;
  filteredUsers: UserData[];
}

const initialState: AuthState = {
  user: null,
  isLoggedIn: false,
  isSuccess: false,
  filteredUsers: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserData>) {
      state.user = action.payload;
      state.isLoggedIn = true;
    },
    resetAuthState(state) {
      state.user = null;
      state.isLoggedIn = false;
      state.isSuccess = false;
    },
    setSuccess(state, action: PayloadAction<boolean>) {
      state.isSuccess = action.payload;
    },

    FILTER_USERS(state, action: PayloadAction<FilterUsersPayload>) {
      const { users, searchWord } = action.payload;
      const filterr = users.filter(
        (user) =>
          user.name.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.email.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.role.toLowerCase().includes(searchWord.toLowerCase()) ||
          user.experienceLevel.toLowerCase().includes(searchWord.toLowerCase())
      );
      state.filteredUsers = filterr;
    },
  },
});

export const { setUser, resetAuthState, setSuccess, FILTER_USERS} = authSlice.actions;

export default authSlice.reducer;
