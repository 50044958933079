"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { FilterPostPayload, PostData } from "./postInterfaces";

const initialState = {
  filteredposts: [] as PostData[],
};

// ************* SLICES ************* //
const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    FILTER_POSTS(state, action: PayloadAction<FilterPostPayload>) {
      const { publishedPosts, searchWord } = action.payload;
      const filterr = publishedPosts.filter(
        (post) =>
          post.title.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.authorName.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.postStatus.toLowerCase().includes(searchWord.toLowerCase()) ||
          post.postCategories
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase()) ||
          post.topicTags
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase())
      );
      state.filteredposts = filterr;
    },
  },
});

export const { FILTER_POSTS } = postSlice.actions;

export default postSlice.reducer;
