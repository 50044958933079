"use client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { CommentData, FilterCommentPayload } from "./commentInterface";

const initialState = {
  isErrorComment: false,
  isSuccessComment: false,
  isLoadingComment: false,
  isLoadingCreate_Comment: false,
  isLoadingReact_to_Comment: false,
  isLoadingEdit_Comment: false,
  message: "" as string,
  comment: {} as CommentData,
  comments: [] as CommentData[], // Replace '[]' with the appropriate type
  filteredComments: [] as CommentData[],
};

// ************* SLICES ************* //
const commentSlice = createSlice({
  name: "post",
  initialState,
  reducers: {
    FILTER_COMMENTS(state, action: PayloadAction<FilterCommentPayload>) {
      const { comments, searchWord } = action.payload;
      const filterr = comments.filter(
        (comment) =>
          comment.commenterName
            .toLowerCase()
            .includes(searchWord.toLowerCase()) ||
          comment.content.toLowerCase().includes(searchWord.toLowerCase()) ||
          comment.edited
            .toString()
            .toLowerCase()
            .includes(searchWord.toLowerCase())
      );
      state.filteredComments = filterr;
    },
  },
});

export const { FILTER_COMMENTS } = commentSlice.actions;

export default commentSlice.reducer;
