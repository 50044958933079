import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./features/theme/themeSlice";
import authReducer from "./features/auth/authSlice";
import postReducer from "./features/post/postSlice";
import commentReducer from "./features/comment/commentSlice";
import emailReducer from "./features/email/sendEmailSlice";
import emailSubscribeReducer from "./features/emailSubscribe/emailSubscribeSlice";
import postApi from "./features/post/postApi";
import {authApi} from "./features/auth/authApi";
import commentApi from "./features/comment/commentApi";

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    post: postReducer,
    // Add the postApi reducer
    [postApi.reducerPath]: postApi.reducer,
    comment: commentReducer,
    [commentApi.reducerPath]: commentApi.reducer,
    email: emailReducer,
    emailSubscribe: emailSubscribeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(authApi.middleware, postApi.middleware, commentApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
