import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  PostFormData,
  PostStatusUgrade,
  postLikeEmoji,
} from "./postInterfaces";

const backendUrl = process.env.NEXT_PUBLIC_BACKEND_URL_PROD;
export const POST_API_URL = `${backendUrl}/api/post`;

const postApi = createApi({
  reducerPath: "postApi",
  baseQuery: fetchBaseQuery({
    baseUrl: POST_API_URL,
    credentials: "include", // For handling cookies/session tokens
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["Posts", "Post", "Admin"],
  endpoints: (builder) => ({
    // SIGNED OUT USER ENDPOINTS
    getLandingPagePopularPost: builder.query({
      query: () => "/getallpopularposts",
      providesTags: ["Posts"],
    }),

    getAllArticles: builder.query({
      query: () => "/getallpostsformetadata",
      providesTags: ["Posts"],
    }),

    // viewLandingPagePost: builder.query({
    //   query: (postID: string) => `/getpostbyid/landingpage/${postID}`,
    //   providesTags: (result, error, postID) => [{ type: "Post", id: postID }],
    // }),

    viewLandingPagePost: builder.query({
      query: (postID) => `/getpostbyid/landingpage/${postID}`,
      providesTags: (result, error, postID) => [{ type: "Post", id: postID }],
      keepUnusedDataFor: 300, // 5 minutes
      async onQueryStarted(postID, { queryFulfilled }) {
        // Prefetch data
        try {
          await queryFulfilled;
        } catch (error) {
          console.error("Error prefetching data:", error);
        }
      },
    }),

    // SIGNED IN USER ENDPOINTS
    getAllViewablePosts: builder.query({
      query: () => "/getallposts",
      providesTags: ["Posts"],
    }),

    viewASinglePost: builder.query({
      query: (postID: string) => `/getpostbyid/mainpage/${postID}`,
      providesTags: (result, error, postID) => [{ type: "Post", id: postID }],
      keepUnusedDataFor: 300, // 5 minutes
      async onQueryStarted(postID, { queryFulfilled }) {
        // Prefetch data
        try {
          await queryFulfilled;
        } catch (error) {
          console.error("Error prefetching data:", error);
        }
      },
    }),

    reactToPost: builder.mutation({
      query: ({ postID, emoji }: { postID: string; emoji: postLikeEmoji }) => ({
        url: `/reactions/reacttopost/${postID}`,
        method: "PATCH",
        body: emoji,
      }),
      invalidatesTags: (result, error, { postID }) => [
        { type: "Post", id: postID },
      ],
    }),

    shareArticle: builder.mutation({
      query: (postID: string) => ({
        url: `/share-articles/${postID}`,
        method: "PATCH",
      }),
      invalidatesTags: (result, error, postID) => [
        { type: "Post", id: postID },
      ],
    }),

    // ADMIN PANEL ENDPOINTS
    getAllPostDataAdmin: builder.query({
      query: () => "/admingetallposts",
      providesTags: ["Admin"],
    }),

    createPost: builder.mutation({
      query: (PostInfo: PostFormData) => ({
        url: "/createpost",
        method: "POST",
        body: PostInfo,
      }),
      invalidatesTags: ["Posts", "Admin"],
    }),

    editPost: builder.mutation({
      query: ({
        postID,
        PostInfo,
      }: {
        postID: string;
        PostInfo: PostFormData;
      }) => ({
        url: `/editpost/${postID}`,
        method: "PATCH",
        body: PostInfo,
      }),
      invalidatesTags: (result, error, { postID }) => [
        { type: "Post", id: postID },
      ],
    }),

    updatePostStatus: builder.mutation({
      query: (postData: PostStatusUgrade) => ({
        url: "/updatepoststatus",
        method: "PATCH",
        body: postData,
      }),
      invalidatesTags: ["Posts", "Admin"],
    }),

    deletePostByAdmin: builder.mutation({
      query: (postID: string) => ({
        url: `/admindeletepost/${postID}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Posts", "Admin"],
    }),
  }),
});

// Exporting hooks for each endpoint
export const {
  useGetLandingPagePopularPostQuery,
  useGetAllArticlesQuery,
  useViewLandingPagePostQuery,
  useGetAllViewablePostsQuery,
  useViewASinglePostQuery,
  useReactToPostMutation,
  useShareArticleMutation,
  useGetAllPostDataAdminQuery,
  useCreatePostMutation,
  useEditPostMutation,
  useUpdatePostStatusMutation,
  useDeletePostByAdminMutation,
} = postApi;

export default postApi;
